import React from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import Seo from "../components/Seo"

const Wrapper404 = styled.section`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  font-size: 5rem;
  text-transform: uppercase;
  text-align: center;
`

const Error404 = styled.h1`
  font-size: 10rem;
  font-weight: 600;
  padding-bottom: 2rem;
`

const ErrorPage = () => {
  return (
    <Layout>
      <Seo title="404" />
      <Wrapper404>
        <Error404>404</Error404>
        <p>ups.....</p>
        <p>strona nie istnieje</p>
      </Wrapper404>
    </Layout>
  )
}

export default ErrorPage
